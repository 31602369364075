import "./Legal.css";

function Legal() {

    return (
        <div id="Legal">

        </div>
    );
}

export default Legal;